import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Image from 'next/image';
import posthog from 'posthog-js';
import { utils } from '@makeship/core';
import { Disclaimer } from '../Typography';
import Product360Viewer from '../pages/products/Product360Viewer';
import { ProductType } from '../../types/common';
import { getProductIDFromShopifyGID, getModelsMeasurements } from '../../utils/product';

export type ImageGalleryProps = {
  images: Shopify.Image[];
  images360: Shopify.Image[];
  has360View?: boolean;
  product: Shopify.Product;
  isComingSoon?: boolean;
  isPetition?: boolean;
};

const Container = styled.div`
  width: 100%;
  display: flex;
`;

const ImageThumbnailContainer = styled.ul`
  flex: 1 1 10%;
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-right: 40px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    margin-right: 8px;
  }
`;

const MainImageContainer = styled.div`
  flex: 1 1 90%;
  display: flex;
  justify-content: center;
`;

const MainImageWrapper = styled.div`
  max-width: 500px;
`;

const StyledImage = styled(Image)<{ isPetition?: boolean }>`
  border-radius: 0px 8px 8px 8px;
  ${({ isPetition, theme }) =>
    !isPetition &&
    `
    background-color: ${utils.hexToRGBA(theme.colors.cloud, theme.alpha.light)};
  `}
`;

const StyledImageBorder = styled.div<{ isPetition?: boolean }>`
  display: flex;
  border-radius: 0px 8px 8px 8px;
  ${({ isPetition, theme }) =>
    !isPetition &&
    `
      border: 1px solid ${utils.hexToRGBA(theme.colors.midnight, theme.alpha.extraLight)};
    `}
`;

const ImageThumbnailWrapper = styled.li<{ selected: boolean }>`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  cursor: pointer;
  border: 1px solid transparent;

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.neutral6};
  }
`;

const DisclaimerWrapper = styled.div<{ has360View?: boolean }>`
  position: ${({ has360View }) => (has360View ? 'relative' : 'static')};
  top: -56px;
`;

const ImageGallery: React.FC<ImageGalleryProps> = ({
  images,
  images360,
  has360View,
  product,
  isComingSoon,
  isPetition,
}: ImageGalleryProps) => {
  const [selectedImage, setSelectedImage] = useState(0);
  const [currentImages, setCurrentImages] = useState<Shopify.Image[]>(
    has360View ? [images360[0]].concat(images) : images,
  );

  useEffect(() => {
    // Check if we need to load new images or not
    if (images.length > 0 && !(images[0].transformedSrc === currentImages[0].transformedSrc)) {
      if (!has360View) {
        setCurrentImages(images);
        setSelectedImage(0);
      } else if (images360[0].transformedSrc !== currentImages[0].transformedSrc) {
        // Sets vinyl 360 viewer
        const currentImages = [images360[0]].concat(images);
        setCurrentImages(currentImages);
        setSelectedImage(0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images, images360, has360View]);

  const handleOnClick = (index: number) => {
    setSelectedImage(index);
    posthog.capture('selected_image_changed', {
      selected_image_index: index,
      event_location: 'desktop_image_gallery',
      total_image_count: currentImages.length,
      product_type: product.productType,
      product_name: product.title,
      shopify_product_id: getProductIDFromShopifyGID(product.id),
    });
  };

  return (
    <Container data-testid="galleryContainer">
      <ImageThumbnailContainer data-testid="thumbnailContainer">
        {currentImages.map((image, index) => (
          <>
            <ImageThumbnailWrapper
              key={image.transformedSrc}
              selected={index === selectedImage}
              onClick={() => handleOnClick(index)}
            >
              <Image
                data-testid="desktop-thumbnail-image"
                src={image.transformedSrc}
                alt={image.altText || 'product image'}
                width="60"
                height="60"
                quality={75}
                unoptimized
                priority={index === 0}
              />
            </ImageThumbnailWrapper>
          </>
        ))}
      </ImageThumbnailContainer>
      <MainImageContainer data-testid="mainImageContainer">
        <MainImageWrapper>
          {has360View && selectedImage === 0 ? (
            <div data-testid="product-360-viewer-desktop">
              <Product360Viewer imageArray={images360} />
              <DisclaimerWrapper has360View>
                <Disclaimer>3D digital render shown</Disclaimer>
              </DisclaimerWrapper>
            </div>
          ) : (
            <>
              <StyledImageBorder isPetition={isPetition}>
                <StyledImage
                  isPetition={isPetition}
                  src={currentImages[selectedImage]?.transformedSrc || '/assets/default_product_asset.png'}
                  alt={currentImages[selectedImage]?.altText || 'main product image'}
                  width={1000}
                  height={1000}
                  quality={90}
                  unoptimized
                  data-testid="mainImage"
                  priority
                />
              </StyledImageBorder>
              {product.productType === ProductType.VinylFigure && !isComingSoon && (
                <DisclaimerWrapper data-testid="disclaimer">
                  <Disclaimer>3D digital render shown</Disclaimer>
                </DisclaimerWrapper>
              )}
              {isPetition && (
                <DisclaimerWrapper>
                  <Disclaimer>Reference images provided by creator. Final product results may vary.</Disclaimer>
                </DisclaimerWrapper>
              )}
              {!!getModelsMeasurements(product.id) && (
                <DisclaimerWrapper>
                  <Disclaimer>{getModelsMeasurements(product.id)}</Disclaimer>
                </DisclaimerWrapper>
              )}
            </>
          )}
        </MainImageWrapper>
      </MainImageContainer>
    </Container>
  );
};

export default ImageGallery;
